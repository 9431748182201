import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import { init } from '@socialgouv/matomo-next'
import '../../public/styles/globals.sass'
import '../../public/styles/safari.css'
import {
  ChakraProvider,
  extendTheme,
  useColorModeValue as mode
} from '@chakra-ui/react'
import { theme } from '@chakra-ui/pro-theme'

import dynamic from 'next/dynamic'
const CrispNoSSR = dynamic(
  () => import('../services/Crisp'),
  { ssr: false }
)
const SegmentNoSSR = dynamic(
  () => import('../services/Segment'),
  { ssr: false }
)

const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL;
const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID;
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT;

const extendedTheme = extendTheme({
  sizes: {
    container: {
      maxWidth: '100vh'
    }
  },
  components: {
    Button: {
      baseStyle: {
        backgroundColor: "white",
      },
      variants: {
        blue: (props) => ({
          ...theme.components.Button.variants.outline,
          borderColor: "blue.600",
          backgroundColor: "blue.600",
          color: "white",
          borderWidth: "1px",
          _hover: {
            backgroundColor: "white",
            color: "blue.600",
            borderColor: "blue.600",
          },
        }),
      },
    },
  },
  colors: {
    ...theme.colors,
    brand: theme.colors.blue,
    blue: {
      50: "#ebecfd",
      100: "#cdcefa",
      200: "#abaff7",
      300: "#888ef4",
      400: "#6e72f0",
      500: "#5656ea",
      600: "#504dde",
      700: "#4741d1",
      800: "#4035c6",
      900: "#331CB3",
    },
    gray: {
      50: "#fcfcfd",
      100: "#f5f5fa",
      200: "#efeff4",
      300: "#e2e2e7",
      400: "#bfbfc4",
      500: "#a0a0a5",
      600: "#77777b",
      700: "#636367",
      800: "#444448",
      900: "#232326",
    },
    purple: {
      50: "#f3e4f9",
      100: "#dfbcf0",
      200: "#cb8fe7",
      300: "#b561dd",
      400: "#a53bd6",
      500: "#9506ce",
      600: "#840ac8",
      700: "#6c0ac1",
      800: "#4035c6",
      900: "#45039C",
    }
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.15rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  theme,
})

export default function App (
  {
    Component,
    pageProps: { session, ...pageProps }
  }: AppProps
){
  useEffect(() => {
    init({ url: MATOMO_URL, siteId: MATOMO_SITE_ID })
  })

  return (
    // Use of the <SessionProvider> allow components that call `useSession()`
    // anywhere in the application to access the `session` object.
    <SessionProvider
      session={session}
      refetchInterval={0}
    >
      <ChakraProvider theme={extendedTheme}>
        <Component {...pageProps} />
      </ChakraProvider>
    </SessionProvider>
  )
}
